import {
  Footer as FooterUI,
  FooterHeader,
  FooterSection,
  FooterTitle,
} from '~/ui/components/layout/Footer';
import { VerticalLogo } from '~/common/components/Logo/Vertical';
import { cn } from '~/ui/methods/classNames';
import { playerStore } from '~/players/stores/playerStore';

export const Footer = () => (
  <FooterUI
    class={cn('relative z-20', {
      'pb-32': playerStore.visibility === 'shown',
    })}
  >
    <FooterHeader class='flex gap-2.5 items-center h-full'>
      <VerticalLogo class={'h-20'} />
      <p>
        Maastr, LLC ©2020-{new Date().getFullYear()}
        <br />
        All rights reserved
      </p>
    </FooterHeader>
    <FooterSection>
      <FooterTitle>Services</FooterTitle>
      <a class='link link-hover'>Branding</a>
      <a class='link link-hover'>Design</a>
      <a class='link link-hover'>Marketing</a>
      <a class='link link-hover'>Advertisement</a>
    </FooterSection>
    <FooterSection>
      <FooterTitle>Company</FooterTitle>
      <a class='link link-hover'>About us</a>
      <a class='link link-hover'>Contact</a>
      <a class='link link-hover'>Jobs</a>
      <a class='link link-hover'>Press kit</a>
    </FooterSection>
    <FooterSection>
      <FooterTitle>Legal</FooterTitle>
      <a class='link link-hover'>Terms of use</a>
      <a class='link link-hover'>Privacy policy</a>
      <a class='link link-hover'>Cookie policy</a>
    </FooterSection>
  </FooterUI>
);
